import {useRouter} from 'next/router';
import NextLink from 'next/link';
import {Box, BoxProps, Text} from '@chakra-ui/layout';

import {px} from '@eksab/theme';
import {useTranslate} from '@eksab/i18n';

import {useMobileNavHeight} from '../footer/useMobileNavHeight';
import {icons} from './icons';
import {motion} from 'framer-motion';
import {NewBadge} from '../NewBadge';
import type {IconProps} from '@chakra-ui/icon';
import {useRegionSettings} from '@eksab/hooks/useRegionSettings';
import {useWebViewData} from '@eksab/hooks/useWebView';
import {createPortal} from 'react-dom';
import {ReactNode, useEffect, useState} from 'react';
import {create} from 'zustand';

const useNavBarVisibility = create<{
  isNavBarVisible: boolean;
  hideNavBar: () => void;
  showNavBar: () => void;
}>((set) => ({
  isNavBarVisible: true,
  hideNavBar: () => set({isNavBarVisible: false}),
  showNavBar: () => set({isNavBarVisible: true}),
}));

export const AppMobileNav = () => {
  const {webViewData} = useWebViewData();
  const isVisible = useNavBarVisibility((state) => state.isNavBarVisible);
  const {data: regionSettings} = useRegionSettings();

  useEffect(() => {
    if (isVisible && webViewData?.hideNavbar) {
      useNavBarVisibility.getState().hideNavBar();
    }
  }, [webViewData?.hideNavbar, isVisible]);

  return (
    <Box id="mob-nav" inset={0} top="unset" zIndex="sticky" pos="fixed">
      {isVisible && (
        <Box
          as="nav"
          h={'4.7rem'}
          boxShadow="0px -8px 40px rgba(0, 0, 0, 0.15);"
          bgColor="black"
          borderTopRadius="20"
          px={px}
          alignItems="center"
          justifyContent="space-between"
          display={['flex', null, null, 'none']}
        >
          <MobileNavRoute nav={{route: 'fantasy', name: 'fantasy', icon: icons.FantasyIcon}} />

          {!!regionSettings?.settings?.tactic_enabled && (
            <MobileNavRoute nav={{route: 'predictions', name: 'predictions', icon: icons.PredictionsIcon}} />
          )}

          <MobileNavRoute nav={{route: 'trivia/sports', name: 'trivia.sports', icon: icons.TriviaIcon}} />

          <MobileNavRoute nav={{route: 'kataflam', name: 'trivia.art', icon: icons.TriviaFilmIcon}}>
            <NewBadge size="sm" top="-0.5" insetEnd="0" />
          </MobileNavRoute>

          <MobileNavRoute
            nav={{
              route: 'store',
              name: 'store.title',
              icon: icons.StoreIcon,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export const MobileNav = ({children}: {children?: ReactNode}) => {
  const [mobNavNode, setMobNavNode] = useState<HTMLElement>();
  const setBottomNavHeight = useMobileNavHeight((state) => state.setBottomNavHeight);
  const resetBottomNavHeight = useMobileNavHeight((state) => state.reset);
  const {showNavBar, hideNavBar} = useNavBarVisibility();
  useEffect(() => {
    hideNavBar();
    const mobNav = document.getElementById('mob-nav');
    if (mobNav) {
      setMobNavNode(mobNav);
    }
    return () => {
      showNavBar();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!mobNavNode) return;
    mobNavNode.children.length
      ? setBottomNavHeight(`${mobNavNode.children[0].clientHeight}px`)
      : setBottomNavHeight('0px');
    return () => resetBottomNavHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobNavNode]);

  return mobNavNode ? createPortal(children, mobNavNode) : null;
};

interface NavRoute extends BoxProps {
  nav: {
    name: 'fantasy' | 'predictions' | 'groups' | 'store.title' | 'trivia.art' | 'trivia.sports';
    route: string;
    icon: (props: IconProps) => JSX.Element;
  };
}

const MobileNavRoute = ({children, nav: {route, name, icon: Icon}, ...props}: NavRoute) => {
  const {route: currentRoute} = useRouter();
  const isActive = currentRoute.startsWith(`/${route}`);

  const t = useTranslate();

  return (
    <NextLink key={route} href={`/${route}`} passHref legacyBehavior>
      <Box
        as="a"
        pos="relative"
        display="flex"
        flexDir="column"
        gap="0.5"
        alignItems="center"
        cursor="pointer"
        minW="50px"
        {...props}
      >
        <Box pos="relative">
          <Icon boxSize="6" stroke={'gray.500'} fill={'none'} />
          {isActive && (
            <Box as={motion.div} pos="absolute" inset={0} animate={{opacity: 1}} initial={{opacity: 0}}>
              <Icon boxSize="6" d="block" stroke={'green.400'} fill={'green.400'} />
            </Box>
          )}
        </Box>

        <Text
          align="center"
          textTransform="capitalize"
          fontSize="xs"
          color={isActive ? 'green.400' : 'gray.500'}
          fontWeight="600"
        >
          {t(`app.${name}`)}
        </Text>

        {children}
      </Box>
    </NextLink>
  );
};
