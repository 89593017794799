import {config} from '@eksab/config';

type FeatureFlags = 'trivia.sports' | 'trivia.art';

const featureFlags: Record<FeatureFlags, (typeof config.appEnv)[]> = {
  'trivia.sports': ['development', 'staging', 'production'],
  'trivia.art': ['development', 'staging', 'production'],
};

export function isFeatureEnabled(flag: FeatureFlags) {
  return featureFlags[flag].includes(config.appEnv);
}
