import {useState, useEffect, useCallback, EffectCallback} from 'react';

import {useTokenLoginMutation} from '@eksab/features/auth/hooks/useTokenLoginMutation';
import {isServer} from '@eksab/util';

import {useAccessToken} from './useAccessToken';

const STORAGE_KEY = 'webViewData';

export interface WebViewMessage {
  token: string;
  hideHeader: boolean;
  hideNavbar: boolean;
}

export type MobileAppMessage = {
  type: 'invalidate-coins';
};

export function useWebViewData() {
  const [webViewData, setWebViewData] = useState<WebViewMessage | null>(null);

  const isActive = isServer() ? false : 'ReactNativeWebView' in window;

  useEffect(() => {
    const cachedWebViewData = sessionStorage.getItem(STORAGE_KEY);
    if (cachedWebViewData) setWebViewData(JSON.parse(cachedWebViewData));

    const onWebViewMessage = (e: MessageEvent<WebViewMessage>) => {
      setWebViewData(e.data);
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(e.data));
    };

    window.addEventListener('message', onWebViewMessage);

    return () => window.removeEventListener('message', onWebViewMessage);
  }, []);

  const sendDataToMobileApp = useCallback(
    (message: MobileAppMessage) => {
      if (!isActive) return console.warn('WebView is not active');

      // @ts-ignore
      window.ReactNativeWebView?.postMessage(JSON.stringify(message));
    },
    [isActive],
  );

  return {
    webViewData,
    isActive,
    sendDataToMobileApp,
  };
}

/**
 * Hook to run an effect in a webview mode, similar to React.useEffect. This can be used to perform side-effects. The passed callback should be wrapped in React.useCallback to avoid running the effect too often.
 * @param callback — Memoized callback containing the effect, should optionally return a cleanup function.
 * @returns void
 *
 * @example
 * import {useCallback} from 'react';
 *
 * import {useWebViewEffect} from '@eksab/hooks';
 *
 * const ExampleComponent = () => {
 *  useWebViewEffect(
 *    useCallback(() => {
 *      console.log('WebView is Active!');
 *
 *      return () => console.log('Cleanup!');
 *    }, []),
 *  );
 * };
 */

export function useWebViewEffect(effect: EffectCallback) {
  useEffect(() => {
    const isActive = 'ReactNativeWebView' in window;
    if (!isActive) return;

    const cleanup = effect();

    return cleanup;
  }, [effect]);
}

export function useSyncWebViewAuth() {
  const {webViewData} = useWebViewData();
  const {data: accessToken} = useAccessToken();
  const tokenLoginMutation = useTokenLoginMutation();

  useEffect(() => {
    if (accessToken || !webViewData) return;

    tokenLoginMutation.mutate({code: webViewData.token});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webViewData?.token, accessToken]);

  return !!accessToken;
}
